import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Link } from '@chakra-ui/next-js'
import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Menu,
  MenuButton,
  MenuItem as ChakraMenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React from 'react'
import {
  RiArrowDownSLine,
  RiCheckboxCircleFill,
  RiCloseLine,
  RiLogoutBoxRLine,
  RiMenuLine,
} from 'react-icons/ri'
import { useAccount, useDisconnect } from 'wagmi'
import { BalancePill } from 'components/BalancePill'
import { useIsMobileWidth } from 'components/Breakpoint'
import { ConnectButton } from 'components/ConnectButton'
import { Modal } from 'components/Modal'
import { NetworkButton } from 'components/NetworkButton'
import { ThemeSwitch } from 'components/ThemeSwitch'
import { ROUTE_NAMES, Routes } from 'constants/routes'
import { env } from 'env.client'
import { useCopyToClipboard } from 'hooks/useCopyToClipboard'
import { useThemeColors } from 'hooks/useThemeColors'
import { NAVBAR_OFFSET_X } from 'theme/constants'
import { getTruncatedText } from 'utils/getTruncatedText'

const getNavigationStyle = ({
  isActive,
  COLORS,
}: {
  isActive: boolean
  COLORS: ReturnType<typeof useThemeColors>
}) => ({
  py: { base: 0, lg: 6 },
  px: { base: 2, lg: 4 },
  minW: 'fit-content',
  borderBottom: { base: 'none', lg: '1px solid transparent' },
  _hover: {
    textDecoration: 'none',
    ...(!isActive && {
      borderBottom: { base: 'none', lg: '1px solid' },
      borderColor: COLORS.zircuitPrimary,
    }),
  },
  ...(isActive && {
    color: COLORS.zircuitPrimary,
    borderBottom: { base: 'none', lg: '1px solid' },
    borderColor: COLORS.zircuitPrimary,
  }),
})

type NavItemProps = {
  href: string
  pathnames: Array<string>
  label: string
  onClose?: () => void
}

const MenuItem = ({ href, pathnames, label }: Omit<NavItemProps, 'onClick'>) => {
  const COLORS = useThemeColors()
  const { pathname } = useRouter()

  return (
    <ChakraMenuItem as={Link} _hover={{ textDecoration: 'none' }} href={href}>
      <Text
        color={pathnames.includes(pathname) ? COLORS.zircuitPrimary : COLORS.grey02}
        variant="caption2medium"
      >
        {label}
      </Text>
    </ChakraMenuItem>
  )
}

const NavItem = ({ href, onClose, pathnames = [], label }: NavItemProps) => {
  const COLORS = useThemeColors()
  const { pathname } = useRouter()

  return (
    <Link
      href={href}
      onClick={onClose}
      size={{ base: 'link2medium', md: 'link3medium' }}
      {...getNavigationStyle({ isActive: pathnames.includes(pathname), COLORS })}
    >
      {label}
    </Link>
  )
}

const ZircuitLogo = () => {
  const { colorMode } = useColorMode()

  return (
    <Link href={Routes.bridge()}>
      <Image alt="Zircuit logo header" src={`/assets/zircuit_logo_header_${colorMode}.svg`} />
    </Link>
  )
}

export const NavBar = () => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])
  const { pathname } = useRouter()
  const { isConnected, address, connector, status } = useAccount()
  const { disconnect } = useDisconnect()
  const { copyToClipboard, isCopied } = useCopyToClipboard()
  const {
    isOpen: isMobileMenuOpen,
    onOpen: onMobileMenuOpen,
    onClose: onMobileMenuClose,
  } = useDisclosure()
  const {
    isOpen: isAccountMenuOpen,
    onOpen: onAccountMenuOpen,
    onClose: onAccountMenuClose,
  } = useDisclosure()
  const { isMobileWidth } = useIsMobileWidth()

  const isConnecting = status === 'reconnecting' || status === 'connecting'

  return (
    <>
      <Box bgColor={COLORS.bgSecondary} display={{ base: 'block', lg: 'none' }}>
        <Flex alignItems="center" justifyContent="space-between" px={NAVBAR_OFFSET_X} py={5}>
          <ZircuitLogo />
          <Flex alignItems="center" gap={2}>
            <Flex alignItems="center" gap={4}>
              {!isMobileWidth && <ThemeSwitch />}
              {!isConnecting && (
                <Flex>
                  {connector && address ? (
                    <>
                      <Flex alignItems="center" gap={4}>
                        <Button onClick={onAccountMenuOpen} variant="profile">
                          <Image
                            alt="connected dot icon"
                            boxSize={5}
                            ml={-1}
                            mr={1.5}
                            src={`/assets/connected_dot_${colorMode}.svg`}
                          />
                          <RiArrowDownSLine size={20} color={COLORS.dark01} />
                        </Button>
                      </Flex>
                      <Modal
                        title={t('common:ConnectedWith', { connector: connector.name })}
                        isOpen={isAccountMenuOpen}
                        onClose={onAccountMenuClose}
                      >
                        <Flex flexDir="column" alignItems="center">
                          <Flex gap={2} alignItems="center">
                            <Text color={COLORS.grey01} variant="text3regular">
                              {getTruncatedText(address)}
                            </Text>
                            {isCopied ? (
                              <Button
                                onClick={async () => {
                                  await copyToClipboard(address)
                                }}
                                rightIcon={<RiCheckboxCircleFill color={COLORS.white} />}
                              >
                                {t('common:Copied')}
                              </Button>
                            ) : (
                              <Button
                                onClick={async () => {
                                  await copyToClipboard(address)
                                }}
                              >
                                {t('common:CopyAddress')}
                              </Button>
                            )}
                          </Flex>
                          <Button
                            maxW={64}
                            width="100%"
                            mt={6}
                            onClick={() => {
                              disconnect({ connector })
                            }}
                            _active={{ color: COLORS.error }}
                            _hover={{ color: COLORS.error, borderColor: COLORS.error }}
                            _focus={{
                              borderColor: COLORS.error,
                              boxShadow: `0px 0px 0px 2px ${COLORS.errorBg2}`,
                            }}
                            variant="tertiary"
                            leftIcon={<RiLogoutBoxRLine />}
                          >
                            {t('common:Disconnect')}
                          </Button>
                        </Flex>
                      </Modal>
                    </>
                  ) : (
                    <ConnectButton />
                  )}
                </Flex>
              )}
            </Flex>
            <Button
              variant="icon"
              border="none"
              p={2.5}
              h="max-content"
              color={COLORS.dark01}
              bgColor={COLORS.grey07}
              onClick={isMobileMenuOpen ? onMobileMenuClose : onMobileMenuOpen}
            >
              {isMobileMenuOpen ? <RiCloseLine size={20} /> : <RiMenuLine size={20} />}
            </Button>
          </Flex>
        </Flex>
        {isMobileMenuOpen && (
          <Flex color={COLORS.dark01} flexDir="column" px={4} gap={3} mb={3}>
            <Flex>
              <Flex flexDir="column" w="50%" gap={3}>
                <NavItem
                  onClose={onMobileMenuClose}
                  href={Routes.bridge()}
                  pathnames={['/']}
                  label={t('common:pageName.Bridge')}
                />
                <NavItem
                  onClose={onMobileMenuClose}
                  href={Routes.transactions()}
                  pathnames={[`/${ROUTE_NAMES.transactions}`]}
                  label={t('common:pageName.Transactions')}
                />
                <NavItem
                  onClose={onMobileMenuClose}
                  href={Routes.faq()}
                  pathnames={[`/${ROUTE_NAMES.faq}`]}
                  label={t('common:pageName.FAQ')}
                />
              </Flex>
              <Flex flexDir="column" w="50%" gap={3}>
                <NavItem
                  onClose={onMobileMenuClose}
                  href={env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}
                  label={t('common:BlockExplorer')}
                  pathnames={[]}
                />
                <NavItem
                  onClose={onMobileMenuClose}
                  href={env.NEXT_PUBLIC_SUPPORT_URL}
                  label={t('common:pageName.Support')}
                  pathnames={[]}
                />
              </Flex>
            </Flex>
            {isMobileWidth ? (
              <Grid
                gridTemplateColumns="1fr max-content"
                alignItems="center"
                gap={2}
                mt={3}
                {...(!isConnected && !connector && { justifyItems: 'end' })}
              >
                {isConnected && connector && <BalancePill />}
                <ThemeSwitch />
              </Grid>
            ) : (
              isConnected &&
              connector && (
                <Box my={3}>
                  <BalancePill />
                </Box>
              )
            )}
          </Flex>
        )}
      </Box>
      <Flex
        display={{ base: 'none', lg: 'flex' }}
        as="nav"
        px={NAVBAR_OFFSET_X}
        alignItems="center"
        justifyContent="space-between"
        bgColor={COLORS.bgSecondary}
      >
        <Flex alignItems="center" gap={{ base: 8, xl: 14, '2xl': 24 }}>
          <ZircuitLogo />
          <Flex>
            <NavItem href={Routes.bridge()} pathnames={['/']} label={t('common:pageName.Bridge')} />
            <NavItem
              href={Routes.transactions()}
              pathnames={[`/${ROUTE_NAMES.transactions}`]}
              label={t('common:pageName.Transactions')}
            />
            <NavItem
              href={env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}
              label={t('common:BlockExplorer')}
              pathnames={[]}
            />
            <Menu>
              {({ isOpen: isMenuOpen }) => {
                const isActive = pathname === Routes.faq() || isMenuOpen

                return (
                  <>
                    <MenuButton {...getNavigationStyle({ isActive, COLORS })}>
                      <Text
                        {...(isActive && { color: COLORS.zircuitPrimary })}
                        variant="text3medium"
                      >
                        {t('common:pageName.Support')}{' '}
                        {isMenuOpen ? <ChevronUpIcon mr="-1.5" /> : <ChevronDownIcon mr="-1.5" />}
                      </Text>
                    </MenuButton>
                    <MenuList>
                      <MenuItem label="Discord" href={env.NEXT_PUBLIC_SUPPORT_URL} pathnames={[]} />
                      <MenuItem
                        label="FAQ"
                        href={Routes.faq()}
                        pathnames={[`/${ROUTE_NAMES.faq}`]}
                      />
                    </MenuList>
                  </>
                )
              }}
            </Menu>
          </Flex>
        </Flex>
        {!isConnecting && (
          <Flex gap={2} alignItems="center">
            <NetworkButton />
            {connector && address ? (
              <>
                <BalancePill />
                <Popover>
                  <PopoverTrigger>
                    <Button variant="profile" size="medium">
                      <Image
                        alt="connected dot icon"
                        boxSize={5}
                        ml={-1}
                        mr={1.5}
                        src={`/assets/connected_dot_${colorMode}.svg`}
                      />
                      <Text as="span" variant="text3medium" ml={1.5}>
                        {getTruncatedText(address, 5, 5)}
                      </Text>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverBody my={2}>
                      <Box>
                        <Text variant="text3medium">
                          {t('common:ConnectedWith', { connector: connector.name })}
                        </Text>
                        <Flex mt={1} gap={2} alignItems="center">
                          <Text color={COLORS.grey01} variant="text3regular">
                            {getTruncatedText(address)}
                          </Text>
                          {isCopied ? (
                            <Flex ml={2} gap={1} alignItems="center">
                              <Text variant="text3regular" color={COLORS.zircuitPrimary}>
                                {t('common:Copied')}
                              </Text>
                              <RiCheckboxCircleFill color={COLORS.zircuitPrimary} />
                            </Flex>
                          ) : (
                            <Button
                              variant="link"
                              color={COLORS.zircuitPrimary}
                              _hover={{ textDecor: 'none', color: COLORS.zircuitLight }}
                              onClick={async () => {
                                await copyToClipboard(address)
                              }}
                            >
                              {t('common:CopyAddress')}
                            </Button>
                          )}
                        </Flex>
                      </Box>
                      <Button
                        mt={6}
                        onClick={() => {
                          disconnect({ connector })
                        }}
                        _active={{ color: COLORS.error }}
                        _hover={{ color: COLORS.error, borderColor: COLORS.error }}
                        _focus={{
                          borderColor: COLORS.error,
                          boxShadow: `0px 0px 0px 2px ${COLORS.errorBg2}`,
                        }}
                        variant="tertiary"
                        leftIcon={<RiLogoutBoxRLine />}
                      >
                        {t('common:Disconnect')}
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </>
            ) : (
              <ConnectButton />
            )}
            <ThemeSwitch />
          </Flex>
        )}
      </Flex>
    </>
  )
}
