import { Button, type ButtonProps, Flex, Image, Text } from '@chakra-ui/react'
import { uniqBy } from 'lodash'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useConnect } from 'wagmi'
import { Modal } from 'components/Modal'
import { useThemeColors } from 'hooks/useThemeColors'
import { useToast } from 'hooks/useToast'
import { getImageNameByConnector } from 'utils/getImageNameByConnector'

export const ConnectButton = (props: ButtonProps) => {
  const COLORS = useThemeColors()
  const { t } = useTranslation(['common'])
  const toast = useToast()

  const [isConnectWalletModalOpen, setIsConnectWalletModalOpen] = React.useState(false)
  const { connect, connectors } = useConnect()

  return (
    <>
      <Button
        onClick={() => {
          setIsConnectWalletModalOpen(true)
        }}
        {...props}
      >
        {t('common:ConnectWallet')}
      </Button>
      <Modal
        isOpen={isConnectWalletModalOpen}
        title={t('common:ConnectWallet')}
        onClose={() => {
          setIsConnectWalletModalOpen(false)
        }}
      >
        <Flex gap={2} flexDir="column" maxH="22.5rem" overflowY="scroll">
          {/* By using uniqBy we remove duplicates from the list of connectors from multiInjectedProviderDiscovery */}
          {uniqBy(connectors, (connector) => connector.name).map((connector) => (
            <Flex
              w="100%"
              p={5}
              key={connector.id}
              alignItems="center"
              border="1px solid"
              borderColor={COLORS.grey06}
              borderRadius={10}
              cursor="pointer"
              onClick={() => {
                try {
                  connect(
                    { connector },
                    {
                      onSuccess: () => {
                        setIsConnectWalletModalOpen(false)
                      },
                    },
                  )
                } catch {
                  toast({ message: t('common:error.ConnectorNotReady'), status: 'danger' })
                }
              }}
            >
              <Image
                alt={connector.name}
                src={connector.icon || `/assets/${getImageNameByConnector(connector.id)}.svg`}
                style={{
                  width: 35,
                  height: 32,
                }}
              />
              <Text ml={5} variant="title2medium">
                {connector.name}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Modal>
    </>
  )
}
